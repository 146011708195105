.fullwidth {
  width: 140% !important;
}

.width {
  width: 150px;
}

.wrap {
  word-wrap: break-word;
}

.notebold {
  font-weight: 550;
}

.tableTextAlign {
  text-align: left;
}

.tableTextAlignCenter {
  text-align: center;
  overflow-x: auto;
}

.nodataFoundIcon {
  width: 72px;
  height: 72px;
}

.customTableThStyle {
  background-color: #f2f2f2;
  padding: 5px;
  border: 1px solid #ddd;
}

.customTableTdStyle {
  padding: 8px;
  border: 1px solid #ddd;
}

.customButtonStyle {
  padding: 5px 10px;
  background-color: #405189;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.customMainButtonStyle {
  padding: 5px 10px;
  background-color: '#0ab39c';
  color: 'white';
  border: 'none';
  border-radius: '3px';
  cursor: 'pointer';
}

.customInputStyle {
  width: '100%';
  padding: '5px';
  border: '1px solid #ccc';
  border-radius: '3px';
  box-sizing: 'border-box';
}

.buttonMarginStyle {
  margin-bottom: -5px;
}

.masterAliased {
  margin-bottom: -5px;
}

.masterEmployeeWidth {
  width: 72px;
  height: 72px;
}

.interviewFrom {
  padding-left: 150px;
  padding-top: 10px;
}

.interviewFeedBackMargin {
  margin-bottom: 20px;
}

.interviewFeedBackPadding {
  padding-top: 20px;
}

.inputPadding {
  padding-top: 10px;
}

.validateError {
  color: rgb(233, 23, 23);
  font-size: 12px;
}

.feedBackReviewPadding {
  padding-right: 10px;
}

.simpleBar {
  max-height: 220px;
}

.opacity {
  opacity: 0.5;
}

.fixLengthOfCard {
  width: 300px;
}

.maxHeightOfSimpleBar {
  max-height: 400px;
}

.imageStyleOfList {
  width: 40px;
  height: 40px;
}

.imageStyleBirthday {
  width: 330px;
  height: 300px;
}

.nonPrintable {
  display: none;
}

.printable {
  display: contents;
}

.App {
  display: flex;
  justify-content: center;
  height: 100vh;
}

.toggle-container {
  position: relative;
  width: 81px;
  height: 32px;
  background-color: #c4c4c4;
  cursor: pointer;
  user-select: none;
  border-radius: 30px;
  padding: 2px;
  margin-top: 30px;
  margin-left: 0px;
}

.toggle-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 45px;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  cursor: pointer;
  color: #fff;
  background-color: #047417;
  box-shadow: 0 2px 4px rgb(0, 0, 0, 0.25);
  padding: 8px 15px;
  border-radius: 20px;
  position: absolute;
  transition: all 0.2s ease;
  left: 34px;
}

.disable {
  background-color: #707070;
  left: 2px;
}

.t1 {
  margin-top: 70px;
  font-family: Verdana, sans-serif;
  font-size: larger;
}

.emo {
  margin-top: 270px;
  margin-left: -140px;
  font-size: 200px;
}

.login_img {
  min-height: 120px;
  width: 12%;

}